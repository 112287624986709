<div class="strength">
    <ul class="strengthBar mt-2">
        <li class="point" [style.background-color]="bar0"></li>
        <li class="point" [style.background-color]="bar1"></li>
        <li class="point" [style.background-color]="bar2"></li>
        <li class="point" [style.background-color]="bar3"></li>
    </ul>

    @if (passwordToCheck().length) {
        <p class="text-center mb-0" [style.color]="messageColor">{{ message }}</p>
    }
</div>
<p class="text-center mb-0">&nbsp;{{ warning }}</p>
